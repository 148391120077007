.testimonial {

	.tiles-item-inner {
        border-radius: 9px;
		background: get-color(dark, 1);		
	}
}

.testimonial-item-content-costin {
    position: relative;

    &::before {
        content: '';
        display: block;     
        border-radius: 100%;
        width: 90px;
        height: 90px;
        margin-top: 12px;
        margin-bottom: 16px;
        background-image: url(../../../images/costin.jpeg);
        background-repeat: no-repeat;
    }		
}

.testimonial-item-content-david {
    position: relative;

    &::before {
        content: '';
        display: block;     
        border-radius: 100%;
        width: 90px;
        height: 90px;
        margin-top: 12px;
        margin-bottom: 16px;
        background-image: url(../../../images/david.jpeg);
        background-repeat: no-repeat;
    }		
}

.testimonial-item-content-tudor {
    position: relative;

    &::before {
        content: '';
        display: block;     
        border-radius: 100%;
        width: 90px;
        height: 90px;
        margin-top: 12px;
        margin-bottom: 16px;
        background-image: url(../../../images/tudor.jpeg);
        background-repeat: no-repeat;
    }		
}

.testimonial-item-content-stefan {
    position: relative;

    &::before {
        content: '';
        display: block;     
        border-radius: 100%;
        width: 90px;
        height: 90px;
        margin-top: 12px;
        margin-bottom: 16px;
        background-image: url(../../../images/stefan.jpeg);
        background-repeat: no-repeat;
    }		
}

.testimonial-item-content {
    position: relative;		
}

.testimonial-item-footer {
    font-weight: 600;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.has-top-divider {

        &::before {
            background: get-color(dark, 3);
        }
    }
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh; /* Adjust as needed */
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh; /* Adjust as needed */
  }
  
  /* Add this CSS to your stylesheet */
.tags-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .tag {
    background-color: get-color(primary, 2);
    color: get-color(light, 1);
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .go-back-button {
    background-color: get-color(primary, 2);
    color: get-color(light, 1);
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .go-back-button:hover {
    background-color: #d40027;
  }
  

.testimonial-item-name {
    cursor:  pointer;
}

.testimonial-item-link {
	color: get-color(secondary, 3);

	a {
        color: get-color(secondary, 3);
        text-decoration: none;

        &:hover {
            color: get-color(secondary, 2);
        }
	}
}

.testimonial-icon-social {
    padding: 2px;
    border-radius: 2px;
    background-color: get-color(primary, 2);
    color: get-color(dark, 1);
}